



































































import { computed, defineComponent } from '@nuxtjs/composition-api';
import {
  SfButton,
  SfCircleIcon,
  SfIcon,
  SfImage,
  SfLink,
  SfPrice,
  SfProperty,
} from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'SfCollectedProduct',
  components: {
    SfButton,
    SfIcon,
    SfImage,
    SfCircleIcon,
    SfPrice,
    SfLink,
    SfProperty,
    SvgImage,
  },
  model: {
    prop: 'qty',
  },
  props: {
    image: {
      type: String,
      default: '',
    },
    imageWidth: {
      type: [Number, String],
      default: 140,
    },
    imageHeight: {
      type: [Number, String],
      default: 200,
    },
    title: {
      type: String,
      default: '',
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    qty: {
      type: [Number, String],
      default: 1,
    },
    minQty: {
      type: Number,
      default: null,
    },
    maxQty: {
      type: Number,
      default: null,
    },
    link: {
      type: [String, Object],
      default: null,
    },
    hasRemove: {
      type: Boolean,
      default: true,
    },
    hasMoreActions: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const componentIs = computed(() => (props.link ? 'SfLink' : 'div'));

    const quantity = computed(() => (typeof props.qty === 'string' ? Number(props.qty) : props.qty));

    const removeHandler = () => {
      emit('click:remove');
    };

    return {
      componentIs,
      quantity,
      removeHandler,
    };
  },
});
