















































































































import {
  SfSidebar,
  SfHeading,
  SfButton,
  SfProperty,
  SfLink,
  SfLoader,
  SfImage,
} from '@storefront-ui/vue';
import {
  computed,
  defineComponent,
  onMounted,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';
import {
  useUiState,
  useImage,
  useProduct,
} from '~/composables';
import type { Price } from '~/modules/catalog/types';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import EmptyWishlist from '~/modules/wishlist/components/EmptyWishlist.vue';
import SfCollectedProduct from '~/components/JetUi/organisms/Product/SfCollectedProduct.vue';

import SvgImage from '~/components/General/SvgImage.vue';

import type { WishlistItemInterface, ConfigurableProduct, BundleProduct } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'WishlistSidebar',
  components: {
    SfSidebar,
    SfButton,
    SfHeading,
    SfProperty,
    SfCollectedProduct,
    SfLink,
    SfLoader,
    SfImage,
    EmptyWishlist,
    SvgImage,
  },
  setup() {
    const { localeRoute } = useContext();
    const router = useRouter();
    const { isWishlistSidebarOpen, toggleWishlistSidebar } = useUiState();
    const { getProductPath } = useProduct();
    const {
      removeItem, load: loadWishlist, loading,
    } = useWishlist();
    const { isAuthenticated } = useUser();
    const wishlistStore = useWishlistStore();
    const wishlistItems = computed<WishlistItemInterface[]>(
      () => wishlistStore.wishlist?.items_v2?.items ?? [],
    );

    const getItemPrice = (product: WishlistItemInterface): Price => {
      let regular = 0;
      let special = null;

      if (product?.product?.price_range) {
        regular = product?.product?.price_range.minimum_price.regular_price.value;
        const final = product?.product?.price_range.minimum_price.final_price.value;

        if (final < regular) {
          special = final;
        }
      }

      return {
        regular,
        special,
      };
    };

    const totals = computed<{ total: number, subtotal: number }>(
      () => ((wishlistStore.wishlist?.items_v2?.items ?? []).length > 0
        ? wishlistStore.wishlist?.items_v2?.items.reduce((acc, curr) => ({
          total: acc.total + getItemPrice(curr).special,
          subtotal: acc.subtotal + getItemPrice(curr).regular,
        }), ({ total: 0, subtotal: 0 }))
        : ({ total: 0, subtotal: 0 })),
    );

    const totalItems = computed(
      () => wishlistStore.wishlist?.items_count ?? 0,
    );

    const getAttributes = (product: WishlistItemInterface) => (product?.product as ConfigurableProduct)?.configurable_options || [];
    const getBundles = (product: WishlistItemInterface) => (product?.product as BundleProduct)?.items?.map((b) => b.title).flat() || [];
    // @ts-ignore
    const getItemLink = (item: WishlistItemInterface) => localeRoute(getProductPath(item.product));

    const { getMagentoImage, imageSizes } = useImage();
    const isShowGoToWishlistButton = computed(() => wishlistStore.wishlist.items_count > wishlistStore.wishlist?.items_v2?.items.length);

    onMounted(async () => {
      await loadWishlist({ searchParams: { pageSize: 100 } });
    });

    router.afterEach(() => {
      if (isWishlistSidebarOpen.value) {
        toggleWishlistSidebar();
      }
    });

    return {
      getAttributes,
      getBundles,
      getItemLink,
      isAuthenticated,
      isWishlistSidebarOpen,
      wishlistItems,
      removeItem,
      toggleWishlistSidebar,
      totalItems,
      totals,
      wishlist: wishlistStore.wishlist,
      getMagentoImage,
      imageSizes,
      loading,
      getItemPrice,
      isShowGoToWishlistButton,
    };
  },
});
